.datepicker {
  width: -moz-min-content;
  width: min-content;
}

.datepicker:not(.active) {
  display: none;
}

.datepicker-dropdown {
  position: absolute;
  z-index: 1000;
  padding-top: 4px;
}

.datepicker-dropdown.datepicker-orient-top {
  padding-top: 0;
  padding-bottom: 4px;
}

.datepicker-picker {
  display: flex;
  flex-direction: column;
  border-radius: 0.375rem;
  background-color: #fff;
}

.datepicker-dropdown .datepicker-picker {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.datepicker-main {
  flex: auto;
  padding: 2px;
}

.datepicker-footer {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  background-color: #f8f9fa;
}

.datepicker-title {
  box-shadow: inset 0 -1px 1px rgba(0, 0, 0, 0.1);
  background-color: #f8f9fa;
  padding: 0.375rem 0.75rem;
  text-align: center;
  font-weight: 700;
}

.datepicker-controls {
  display: flex;
}

.datepicker-header .datepicker-controls {
  padding: 2px 2px 0;
}

.datepicker-controls .btn {
  border-color: #f8f9fa;
  background-color: #fff;
}

.datepicker-controls .btn:hover {
  border-color: #dbe0e5;
  background-color: #e2e6ea;
  color: #000;
}

.datepicker-controls .btn:focus {
  border-color: #dbe0e5;
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
  background-color: #e2e6ea;
  color: #000;
}

.datepicker-controls .btn:disabled {
  border-color: #f8f9fa;
  background-color: #f8f9fa;
  color: #000;
}

.datepicker-controls .btn:not(:disabled):active {
  border-color: #d4dae0;
  background-color: #dbe0e5;
  color: #000;
}

.datepicker-controls .btn:not(:disabled):active:focus {
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}

.datepicker-header .datepicker-controls .btn {
  border-color: transparent;
  font-weight: bold;
}

.datepicker-footer .datepicker-controls .btn {
  flex: auto;
  margin: calc(0.375rem - 1px) 0.375rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
}

.datepicker-controls .view-switch {
  flex: auto;
}

.datepicker-controls .prev-button,
.datepicker-controls .next-button {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
  flex: 0 0 14.2857142857%;
}

.datepicker-controls .prev-button.disabled,
.datepicker-controls .next-button.disabled {
  visibility: hidden;
}

.datepicker-view,
.datepicker-grid {
  display: flex;
}

.datepicker-view {
  align-items: stretch;
  width: 15.75rem;
}

.datepicker-grid {
  flex-wrap: wrap;
  flex: auto;
}

.datepicker .days {
  display: flex;
  flex-direction: column;
  flex: auto;
}

.datepicker .days-of-week {
  display: flex;
}

.datepicker .week-numbers {
  display: flex;
  flex-direction: column;
  flex: 0 0 9.6774193548%;
}

.datepicker .weeks {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: auto;
}

.datepicker span {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.datepicker .dow {
  height: 1.5rem;
  font-size: 0.9375rem;
  font-weight: 700;
}

.datepicker .week {
  flex: auto;
  color: #dee2e6;
  font-size: 0.875rem;
}

.datepicker-cell,
.datepicker .days .dow {
  flex-basis: 14.2857142857%;
}

.datepicker-cell {
  height: 2.25rem;
}

.datepicker-cell:not(.day) {
  flex-basis: 25%;
  height: 4.5rem;
}

.datepicker-cell:not(.disabled):hover {
  background-color: #f9f9f9;
  cursor: pointer;
}

.datepicker-cell.focused:not(.selected) {
  background-color: #e2e6ea;
}

.datepicker-cell.selected,
.datepicker-cell.selected:hover {
  background-color: #0d6efd;
  color: #fff;
  font-weight: 600;
}

.datepicker-cell.disabled {
  color: rgba(33, 37, 41, 0.5);
}

.datepicker-cell.prev:not(.disabled),
.datepicker-cell.next:not(.disabled) {
  color: #6c757d;
}

.datepicker-cell.prev.selected,
.datepicker-cell.next.selected {
  color: #e6e6e6;
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today) {
  border-radius: 0;
  background-color: #f8f9fa;
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today):not(.disabled):hover {
  background-color: #f1f3f5;
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today).focused {
  background-color: #e2e6ea;
}

.datepicker-cell.today:not(.selected) {
  background-color: #20c997;
}

.datepicker-cell.today:not(.selected):not(.disabled) {
  color: #fff;
}

.datepicker-cell.today.focused:not(.selected) {
  background-color: #1fc493;
}

.datepicker-cell.range-end:not(.selected),
.datepicker-cell.range-start:not(.selected) {
  background-color: #6c757d;
  color: #fff;
}

.datepicker-cell.range-end.focused:not(.selected),
.datepicker-cell.range-start.focused:not(.selected) {
  background-color: #69727a;
}

.datepicker-cell.range-start:not(.range-end) {
  border-radius: 0.375rem 0 0 0.375rem;
}

.datepicker-cell.range-end:not(.range-start) {
  border-radius: 0 0.375rem 0.375rem 0;
}

.datepicker-cell.range {
  border-radius: 0;
  background-color: #e9ecef;
}

.datepicker-cell.range:not(.disabled):not(.focused):not(.today):hover {
  background-color: #e2e6ea;
}

.datepicker-cell.range.disabled {
  color: #ced4db;
}

.datepicker-cell.range.focused {
  background-color: #dbe0e5;
}

.datepicker-input.in-edit {
  border-color: #73acfe;
}

.datepicker-input.in-edit:focus,
.datepicker-input.in-edit:active {
  box-shadow: 0 0 0.25em 0.25em rgba(115, 172, 254, 0.2);
}